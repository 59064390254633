import { Link } from "gatsby"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import React, { useRef, useEffect, useState } from "react"
import Overlay from "./Overlay"
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css';
import Lottie from 'react-lottie';
import soundCurve from '../../static/sound.json'
import spotify from "../images/spotify.png"
import { isMobileOnly } from "react-device-detect"

const menuItem = {
  closed: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0,
    },
    x: -20,
  },
  open: key => ({
    opacity: 1,
    transition: {
      delay: 0.25 + key * 0.1,
      type: "tween",
    },
    x: 0,
  }),
}

const MenuMobile = ({ play, setPlay, sound, links, isOpen, setIsOpen }) => {

  const [muted, setMuted] = useState(false)
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: soundCurve,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  // const refAudio = useRef()

  // useEffect(() => {
  //   if (sound && refAudio?.current?.audio){
  //     const promise = refAudio.current.audio.current.play()
  //     promise.then(_ => {
  //       console.log("Autoplay worked, your MEI index is high enough, for more information visit: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes")
  //     }).catch(error => {
  //       console.error("Autoplay was blocked by autoplayPolicies, for more information visit: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes")
  //       setPlay(false)
  //       setMuted(true)
  //       const retryPromise = refAudio.current.audio.current.play()
  //       retryPromise
  //         .then(_ => {
  //           setPlay(true)
  //           console.log("Retry autoplay worked in mute, for more information visit: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes")
  //         })
  //         .catch(error => {
  //           console.error("Muted autoplay was blocked by autoplayPolicies, for more information visit: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes")
  //         })
  //     })
  //   }
  // }, [])

  return (
    <Overlay isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="container flex flex-col justify-center">
        <ul className="text-center">
           
            
          {links.map((link, key) => (
            <motion.li
              className="my-8"
              animate={isOpen ? "open" : "closed"}
              custom={key}
              key={`menu_mobile_link${key}`}
              variants={menuItem}
            >
              <Link
                className="font-semibold font-univers text-4xl md:text-7xl text-white"
                activeClassName="text-green-300"
                to={link.to}
                onClick={() => setIsOpen(false)}
                
              >
                <span className="glitch-moz" data-text={link.name}>
                  {link.name}
                </span>
              </Link>
            </motion.li>
          ))}
          <div className="mt-24">
          <span>Now playing Bluagata - Sabba / Sabba</span>
          {/* <div className="flex sm:hidden justify-center items-center">
            <div>
              {isMobileOnly && sound &&
                <AudioPlayer
                  ref={refAudio}
                  src={sound}
                  muted={muted}
                  onPlay={() => setPlay(true)}
                  onPause={() => setPlay(false)}
                  autoPlay
                  controls
                  showJumpControls={false}
                  showDownloadProgress={false}
                  showFilledProgress={false}
                  showFilledVolume={false}
                  showSkipControls={false}
                  customProgressBarSection={[]}
                  customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                  show
                />
              }
            </div>
            <div className="ml-2">
              {isMobileOnly && sound &&
                <Lottie options={defaultOptions}
                  height={"2rem"}
                  width={"2rem"}
                  isPaused={!play}
                />
              }
            </div>
          </div> */}

          <div className="font-univers text-base text-white flex flex-col justify-center items-center  pt-8">
              <button
                className={`
                    font-univers
                    btn px-4 bg-transparent border-white text-white shadow-invButton hover:shadow-invButtonHover focus:shadow-invButtonActive' border-2 rounded-sm font-bold text-lg p-3 duration-300 
                    `
                }
              >
                <a href="https://open.spotify.com/artist/7omRRCthvOtMCaRhEHx30S?si=1ICa4xR6TFm_sBA2fNx7GA&dl_branch=1" className="flex flex-col items-center justify-center">
                  <div className="flex justify-center items-center">
                    <img src={spotify} className="h-6 w-auto mx-3"></img>
                    <span>Follow us on Spotify</span>
                  </div>
                </a>
              </button>
            </div>
          </div>
          
        </ul>
      </div>
    </Overlay>
  )
}

MenuMobile.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}

export default MenuMobile
