import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import MenuMobile from "./MenuMobile"
import { FaBars } from "react-icons/fa"
import logo from '../images/marty-logo.png'

const Header = ({ setPlay, play, sound}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  `)

  return (
    <div className="md:hidden md:pt-12 bg-black border-b-2 border-l-4 border-t-4 border-r-0">
      <div className="flex justify-between">
        <div className="my-auto py-2 ml-6">
          <Link to="/" className="">
            <img alt="Logo" className="w-32 md:w-32" src={logo} />
          </Link>
          
        </div>
        <div className="flex items-center px-6 border-2 border-b-0 border-t-0">
          <button
            className=""
            onClick={() => setIsMenuOpen(true)}
            aria-label="Open Menu"
          >
            <FaBars className="h-6 w-auto text-white fill-current " />
          </button>
        </div>
        

        <div className="hidden">
          {site.data.menu.map((link, key) => (
            <Link
              key={`menu_desktop_link${key}`}
              className="ml-6 sm:ml-8 text-sm sm:text-base font-medium px-px border-b-2 pb-2 border-transparent text-white hover:white hover:border-gray-200 transition duration-150 ease-in-out"
              activeClassName="border-blue-600 text-gray-900 hover:border-blue-600"
              to={link.to}
            >
              {link.name}
            </Link>
          ))}
        </div>
      </div>
      <MenuMobile
        setPlay={setPlay}
        play={play}
        sound={sound}
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        links={site.data.menu}
      />
    </div>
  )
}

export default Header
