import { graphql } from "gatsby"
import React, { useState, useRef, useEffect } from "react"
import Hero from "../components/Hero"
import logo from '../images/marty-logo.png'
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import { FaBars, FaVolumeUp, FaVolumeMute } from "react-icons/fa"
import logoVertical from '../images/marty-logo-vertical.png'
import HorizontalScrollingLoop from "../components/HorizontalScrollingLoop"
import marty02 from "../images/marty-02.jpg"
import marty09 from "../images/marty-09.jpg"
import hearth from "../images/hearth.png"
import outside from "../images/outside.jpg"
import { Reveal, Tween } from 'react-gsap'
import MenuMobile from '../components/MenuMobile'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css';
import Lottie from 'react-lottie';
import soundCurve from '../../static/sound.json'
import spotify from "../images/spotify.png"
import { isDesktop, isMobileOnly, isTablet } from "react-device-detect"

const MAINTENANCE = false

const IndexPage = ({ data }) => {

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [play, setPlay] = useState(true)
  const [muted, setMuted] = useState(false)
  const {
    brands: { logos },
    gallery,
    sound,
    site: { siteMetadata: { links: { facebook, farfetch, instagram }, menu } } } = data
  const { photo1, photo2, photo3, photo4 } = gallery
  const refAudio = useRef()

  useEffect(() => {
    console.log("Setting:", muted)
  }, [muted])

  useEffect(() => {
    if (sound?.song?.file?.url && refAudio?.current?.audio) {
      const promise = refAudio.current.audio.current.play()
      promise.then(_ => {
        console.log("Autoplay worked, your MEI index is high enough, for more information visit: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes")
      }).catch(error => {
        console.error("Autoplay was blocked by autoplayPolicies, for more information visit: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes")
        setPlay(false)
        setMuted(true)
        const retryPromise = refAudio.current.audio.current.play()
        retryPromise
          .then(_ => {
            setPlay(true)
            console.log("Retry autoplay worked in mute, for more information visit: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes")
          })
          .catch(error => {
            console.error("Muted autoplay was blocked by autoplayPolicies, for more information visit: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes")
          })

      })
    }
  }, [])

  if (MAINTENANCE) {
    return (
      <div className="flex flex-row items-center justify-center bg-black h-screen overflow-x-hidden overflow-y-hidden relative md:p-16" >
        <div class="glitch absolute">
          <div class="glitch__item maintenance" ></div>
          <div class="glitch__item maintenance" ></div>
          <div class="glitch__item "></div>
          <div class="glitch__item maintenance "></div>
          <div class="glitch__item" ></div>
        </div>

      </div>
    )
  }


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: soundCurve,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };



  return (
    <Layout play={play} setPlay={setPlay} sound={sound?.song?.file?.url}>
      <div className="bg-black" style={{ backgroundColor: '#000000' }}>
        <SiteMetadata title="Home | Marty Prato" description="Fashion shop since 1974, located in Prato" />
        <div className="flex border-4 border-t-0 md:border-t-4 h-screen sticky top-0 ">
          <div className="hidden md:flex w-navbar border-r border-t-0 border-b-0 flex-col justify-center relative ">
            <div className="mx-2 my-4 absolute top-0">
              <img className="w-32" src={logoVertical}></img>
            </div>
            <div className="border border-r-0 border-l-0 py-6 flex justify-center items-center justify-self-center">
              <button
                className=""
                onClick={() => setIsMenuOpen(true)}
                aria-label="Open Menu"
              >
                <FaBars style={{ transform: "rotate(-90deg)" }} className="h-6 w-auto text-white fill-current " />
              </button>
            </div>

            <div className="absolute bottom-0 mx-auto transform -translate-x-1/2 " style={{ left: "50%" }}>
              {/* <button className="w-full flex flex-row items-center justify-center mb-4" onClick={() => setMuted(prevMuted => !prevMuted)}>
                {muted ? <FaVolumeMute style={{ transform: "rotate(-90deg)" }} className="h-6 w-auto text-white fill-current " />
                  : <FaVolumeUp style={{ transform: "rotate(-90deg)" }} className="h-6 w-auto text-white fill-current " />}
              </button> */}
              <div className="" style={{ transform: "rotate(90deg)" }}>
                {(isDesktop || isTablet) && sound?.song?.file?.url &&
                  <Lottie options={defaultOptions}
                    height={isDesktop ? "2rem" : "1rem"}
                    width={isDesktop ? "2rem" : "1rem"}
                    isPaused={!play}
                  />
                }
              </div>
              <div style={{ transform: "rotate(-90deg)" }}>
                {(isDesktop || isTablet) && sound?.song?.file?.url &&
                  <AudioPlayer
                    ref={refAudio}
                    src={sound?.song?.file?.url}
                    onPlay={() => setPlay(true)}
                    onPause={() => setPlay(false)}
                    muted={muted}
                    autoPlay={false}
                    controls
                    showJumpControls={false}
                    showDownloadProgress={false}
                    showFilledProgress={false}
                    showFilledVolume={false}
                    showSkipControls={false}
                    customProgressBarSection={[]}
                    customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                    show
                  />
                }
              </div>
            </div>
          </div>
          <div className="overflow-y-scroll overflow-x-hidden">
            <MenuMobile
              isOpen={isMenuOpen}
              setIsOpen={setIsMenuOpen}
              links={menu}
            />
            <div className="sm:h-screen md:pt-6 pb-12 lg:pb-16 flex overflow-x-hidden overflow-y-hidden relative ">
              <div class="glitch absolute  md:w-screen md:h-screen">
                <div class="glitch__item"></div>
                <div class="glitch__item"></div>
                <div class="glitch__item"></div>
                <div class="glitch__item"></div>
                <div class="glitch__item"></div>
              </div>
              <div className="hidden z-10 md:flex md:justify-end items-center px-12 w-full">
                <div className="md:w-2/5 flex flex-col justify-end">
                  <img alt="Logo" className="" src={logo} />
                  <span className="text-white text-base md:text-xl lg:text-5xl font-univers md:-mt-8 lg:-mt-12 mb-8 tracking-tighter leading-none w-2/3">
                    fashion shop since 1974
                  </span>
                </div>
              </div>

              <div className="flex md:hidden absolute flex-col items-start justify-end h-screen pb-16">
                <span className="text-white tracking-tighter leading-none text-6xl font-univers ml-2">
                  fashion shop since 1974
                </span>
                {(isMobileOnly) && sound?.song?.file?.url &&
                  <div className="flex flex-col ml-2">
                    <span className="text-white font-bold text-sm">Now playing</span>
                    <span className="text-white">
                      Bluagata - Sabba / Sabba
                    </span>
                  </div>
                }
                <div className="flex flex-row justify-center items-center ml-2">
                  {(isMobileOnly) && sound?.song?.file?.url &&
                    <Lottie options={defaultOptions}
                      height={isDesktop ? "2rem" : "1rem"}
                      width={isDesktop ? "2rem" : "1rem"}
                      isPaused={!play}
                    />
                  }
                  {(isMobileOnly) && sound?.song?.file?.url &&
                    <AudioPlayer
                      ref={refAudio}
                      src={sound?.song?.file?.url}
                      onPlay={() => setPlay(true)}
                      onPause={() => setPlay(false)}
                      muted={muted}
                      autoPlay={false}
                      controls
                      showJumpControls={false}
                      showDownloadProgress={false}
                      showFilledProgress={false}
                      showFilledVolume={false}
                      showSkipControls={false}
                      customProgressBarSection={[]}
                      customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                      show
                    />
                  }
                </div>
              </div>

              {/* <div className="relative w-2/3 z-10">
      <img className="md:h-full md:w-auto md:max-w-none" src={hero}></img>
    </div>
     */}

            </div>
            <div className="my-24 md:my-32">
              <HorizontalScrollingLoop />
            </div>

            <div className="bg-black z-20" id="first">
              <div className="">
                <img src={marty02}></img>
              </div>


              <div className="my-24 mx-4 flex justify-around items-baseline">

                <div className="wrapper  font-serif">
                  <a href={farfetch}>
                    <div className="marquee tracking-wider">
                      <p className="text-6xl md:text-biggest  text-white flex">
                        <span className="text-white  font-sans font-light mr-2 md:mr-8">BUY</span>
                        <span className="text-black font-display  bg-white mr-2 md:mr-8"> LESS </span>
                        <span className="text-white  font-sans font-light  mr-2 md:mr-8">BUY</span>
                        <span className="text-white font-univers  font-extrabold mr-2 md:mr-8">BETTER </span>
                      </p>
                      <p className="text-6xl md:text-biggest text-white">
                        <span className="text-white font-sans font-light mr-2 md:mr-8">BUY</span>
                        <span className="text-black font-display  bg-white mr-2 md:mr-8"> LESS </span>
                        <span className="text-white  font-sans font-light  mr-2 md:mr-8">BUY</span>
                        <span className="text-white font-univers  font-extrabold mr-2 md:mr-8">BETTER </span>
                      </p>
                    </div>
                  </a>
                  <div className="text-white flex justify-center">
                    <button
                      className={`
                          font-univers
                          btn px-4 bg-transparent border-white text-white shadow-invButton hover:shadow-invButtonHover focus:shadow-invButtonActive' border-2 rounded-sm font-bold text-lg p-3 duration-300 
                          `
                      }
                    >
                      <a href={farfetch}>Visita il nostro store su FARFETCH</a>
                    </button>
                  </div>
                </div>
                {/* <div className="wrapper font-univers">
                    <div className="marquee ">
                        <p className="text-4xl md:text-9xl  text-white">
                        {" "} RICERCA ED EMOZIONE. CONTAMINAZIONE E PERSONALITÀ. EQUILIBRIO E IDENTITÀ.{" "}
                        </p>
                        <p className="text-4xl md:text-9xl text-white">
                        {" "} RICERCA ED EMOZIONE. CONTAMINAZIONE E PERSONALITÀ. EQUILIBRIO E IDENTITÀ.{" "}
                        
                        </p>
                    </div>
                </div> */}
                {/* <div>
                  <span className="text-white text-6xl font-sans font-light">BUY</span>
                  <span className="text-black font-display text-8xl bg-white mr-2"> LESS </span>
                  <span className="text-white text-6xl font-sans font-light ">BUY</span>
                  <span className="text-white font-univers text-8xl font-extrabold">BETTER </span>
                </div>
                <div className="md:w-1/3 leading-tight self-center">
                  <span className="text-white text-lg ">
                    Marty è un percorso visionario che non passa
                    soltanto da capi, abiti e accessori, ma anche
                    dalle persone che vivono questo luogo.
                </span>
                </div> */}

              </div>

              <div
                className="md:p-32 md:pt-6 md:mr-16"
              >
                <img src={outside}></img>
              </div>



              <div className="flex flex-col my-32 mx-4 md:mx-32 text-center" id="second">

                <span className="text-white text-center text-5xl md:text-7xl font-univers leading-none">
                  Marty è il luogo dove il passato incontra il futuro.
                </span>
                {/* <span className="text-white text-center text-2xl md:text-2xl font-univers leading-tight md:px-32 mt-4">
                  In un momento di <span className="outline-w text-black">tutto e subito</span>, vorremmo che
                  la selezione dei nostri capi rimanesse all’interno
                  del vostro <span>guardaroba</span> <span className="font-univers outline-w">a lungo. </span>
                </span> */}
              </div>

              <div className="md:my-16 md:ml-32 md:mr-8 flex flex-col md:flex-row">
                <div className="md:mr-16 p-4 md:p-0">
                  <Reveal repeat>
                    <Tween from={{ opacity: 0 }} duration={0.2} >
                      <picture>
                        <source srcSet={photo1?.fluid?.srcWebp} className="float-image-1" type="image/webp" />
                        <img src={photo1?.fluid?.src} className="float-image-1"></img>
                      </picture>
                    </Tween>
                  </Reveal>
                </div>
                <div className="md:my-16 md:mx-8  p-4 md:p-0">
                  <Reveal repeat>
                    <Tween from={{ opacity: 0 }} duration={0.2} >
                      <picture>
                        <source className="float-image-2 md:w-2/3 h-auto" srcSet={photo2?.fluid?.srcWebp} type="image/webp" />
                        <img className="float-image-2 md:w-2/3 h-auto" src={photo2?.fluid?.src}></img>
                      </picture>
                    </Tween>
                  </Reveal>
                </div>
              </div>
              <div className="md:my-16 md:ml-32 md:mr-8 flex flex-col md:flex-row">
                <div className="md:my-16 md:mx-8  p-4 md:p-0">
                  <Reveal repeat>
                    <Tween from={{ opacity: 0 }} duration={0.2} >
                      <picture>
                        <source className="float-image-3 md:w-2/3 h-auto" srcSet={photo3?.fluid?.srcWebp} type="image/webp" />
                        <img className="float-image-3 md:w-2/3 h-auto" src={photo3?.fluid?.src}></img>
                      </picture>
                    </Tween>
                  </Reveal>
                </div>
                <div className="md:mr-16 md:-mt-16  p-4 md:p-0">
                  <Reveal repeat>
                    <Tween from={{ opacity: 0 }} duration={0.2} >
                      <picture>
                        <source srcSet={photo4?.fluid?.srcWebp} className="float-image-4" type="image/webp" />
                        <img src={photo4?.fluid?.src} className="float-image-4"></img>
                      </picture>
                    </Tween>
                  </Reveal>
                </div>
              </div>

              <div className="my-8 md:my-32 md:mx-8 flex">

                <div className="my-16 mx-4 flex sm:hidden flex-col h-screen">
                  <div className="flex flex-col justify-between">
                    <div className="flex flex-row">
                      <div className="w-4/12  flex justify-end tracking-tighter ml-4">
                        <span className="text-white font-univers text-4xl  -mr-4 vertical-text">SENZA</span>
                        <span className="text-white font-univers text-6xl -mx-2 vertical-text">TEMPO</span>
                      </div>
                      <div className="w-8/12 flex flex-col justify-end mr-12">
                        <span className="text-white text-base text-justify">
                          Marty è il luogo dove il passato incontra il futuro: dall’arredamento futuristico del piano terra a un primo piano che conserva le caratteristiche dell’edificio del 1974.
                        </span>
                        <span className="text-white text-justify mt-1 text-base ">
                          Siamo il luogo dove ci troviamo, siamo le persone che ogni giorno attraverso il proprio percorso visionario cercano e sperano di dare un’emozione, una sicurezza e una visione del mondo che passi non solo attraverso gli abiti, che da più di 40 anni vivono i nostri spazi, ma anche attraverso tutto quello che siamo.
                        </span>
                        <span className="text-white mt-1 text-justify text-base ">
                          Qui le creazioni dei migliori brand e designer di moda di tutto il mondo trovano la loro casa.
                        </span>
                      </div>
                    </div>
                    <div className="md:-mt-4 mx-4">
                      <span className="text-white font-univers tracking-tighter text-screen">OVUNQUE</span>
                    </div>
                    <div className=" w-full md:flex justify-end items-end">
                      <img src={marty09}></img>
                    </div>
                  </div>

                </div>
                <div className="my-16 mx-4 hidden sm:flex flex-col h-screen">

                  <div className="flex flex-row justify-between">
                    <div className=" w-full sm:flex justify-end items-end">
                      <img src={marty09}></img>
                    </div>
                    <div className="w-3/12 md:w-3/12 flex justify-end tracking-tighter md:mx-4">
                      <span className="text-white font-univers text-5xl lg:text-7xl -mr-4 md:-mr-6 vertical-text">SENZA</span>
                      <span className="text-white font-univers text-6xl lg:text-8xl  vertical-text">TEMPO</span>
                    </div>
                    <div className="w-4/12 md:w-4/12 md:-ml-8 flex flex-col justify-end mr-2 tracking-tighter leading-none sm:tracking-tight sm:leading-tight">
                      <span className="text-white text-sm md:text-xs lg:text-sm text-justify ">
                          Marty è il luogo dove il passato incontra il futuro: dall’arredamento futuristico del piano terra a un primo piano che conserva le caratteristiche dell’edificio del 1974.
                      </span>
                      <span className="text-white text-justify mt-1 text-sm md:text-xs lg:text-sm">
                          Siamo il luogo dove ci troviamo, siamo le persone che ogni giorno attraverso il proprio percorso visionario cercano e sperano di dare un’emozione, una sicurezza e una visione del mondo che passi non solo attraverso gli abiti, che da più di 40 anni vivono i nostri spazi, ma anche attraverso tutto quello che siamo.
                      </span>
                      <span className="text-white mt-1 text-justify text-sm md:text-xs lg:text-sm">
                          Qui le creazioni dei migliori brand e designer di moda di tutto il mondo trovano la loro casa.
                      </span>
                    </div>

                  </div>
                  <div className="md:-mt-4">
                    <span className="text-white font-univers tracking-tighter text-3xl sm:text-screen lg:text-screen md:text-screen">OVUNQUE</span>

                  </div>
                </div>
              </div>
            </div>
            {/* <div className="text-8xl flex items-center justify-center my-24">
              <span className="text-white text-6x font-univers">B</span>
              <span className="text-black bg-white text-6x font-display">R</span>
              <span className="text-white text-6x font-sans">A</span>
              <span className="text-white text-6x font-serif">N</span>
              <span className="text-white text-6x font-univers">D</span>
            </div> */}
            <div className="flex flex-col items-center justify-center  relative ">
              {/* <div className="flex flex-col opacity-25 absolute">
                {Array(10).fill().map(() => (
                  <div className="flex">
                    {Array(20).fill(brandsBg).map(el => el)}
                  </div>
                ))}
              </div> */}


              <div className="flex px-24 my-32 w-full" id="brands">
                <ul className="flex flex-wrap">
                  {logos.map(logo => {
                    const { fluid: { src, srcWebp } } = logo

                    return (
                      <li className="flex-grow my-6" style={{ height: "6rem" }}>
                        <picture className="bg-transparent max-h-full max-w-full object-cover">
                          <source srcSet={srcWebp} className="bg-transparent max-h-full max-w-full object-cover p-2" type="image/webp" />
                          <img className="bg-transparent max-h-full max-w-full object-cover p-2" src={src}></img>
                        </picture>
                      </li>

                    )

                  }
                  )}
                </ul>

              </div>

            </div>
            <div className="mt-64 relative" id="contact">

              <div className="z-20">
                <div className="flex flex-col text-white justify-center items-center my-32">
                  <span className="font-display text-base md:text-xl  mb-2">Seguici su instagram</span>
                  <span className="text-xl md:text-6xl  font-univers text-white  font-black"><a href={instagram}>@martyprato</a></span>
                </div>
                <div className="flex flex-col text-white justify-center items-center my-24">
                  <span className="font-display text-base md:text-xl  mb-2">Vieni a trovarci in</span>
                  <span className="text-lg md:text-4xl  font-univers text-white  font-black">Via Fratelli da Maiano, 16</span>
                  <span className="text-lg md:text-4xl  font-univers text-white  font-black">59100 Prato PO</span>
                </div>
                <div className="flex flex-col text-white border-gray-400 mt-2 mb-8 py-16 mx-16 justify-center items-center" >
                  <span className="font-display text-base md:text-xl">Scrivici a</span>
                  <div className="">
                    <a href="mailto:martyartmode@gmail.com">
                      <span className="text-2xl md:text-7xl  font-univers text-white  font-black">
                        martyartmode
                        <span className="outline-w text-black">@</span>
                        gmail
                        <span className="outline-w text-black">.</span>
                        com
                      </span>
                    </a>

                  </div>

                </div>
              </div>


            </div>



            <div className="font-univers text-base text-white flex flex-col justify-center items-center my-16 ">
              <a href="https://open.spotify.com/artist/7omRRCthvOtMCaRhEHx30S?si=1ICa4xR6TFm_sBA2fNx7GA&dl_branch=1" className="flex flex-col items-center justify-center">
                <div className="flex">
                  <span className="text-lg">Music by</span>
                  <img src={spotify} className="h-6 w-auto mx-3"></img>
                  <span className="text-lg">Bluagata</span>
                </div>
                <div className="mt-4">
                  <span>Follow us on Spotify</span>
                </div>

              </a>

            </div>
            <div className="flex border-2 text-white border-gray-400 mt-2 mb-8 py-4 mx-16 justify-center items-center" >
              <span className="text-white text-xs p-3 md:p-0">
                Copyright Marty (Artmode srl) | Via Fratelli da Maiano, 16, 59100 Prato PO | P.IVA 02378930974
              </span>

            </div>
            <div className="font-univers text-xs nowrap text-white flex h-6 justify-center items-end my-16">
              <a href="https://trame-digitali.it" className="flex items-end">
                <span>Made with</span>
                <img src={hearth} className="h-6 w-auto mx-3"></img>
                <span>by Trame Digitali</span>
              </a>
            </div>
          </div>
        </div>
      </div>

    </Layout >
  )
}

export default IndexPage

export const query = graphql`
  query getAssets   {
    brands: contentfulBrands {
      id
      logos {
        id
        fluid {
          src
          srcWebp
        }
      }
    }
    gallery: contentfulPhotoGallery {
      photo1 {
        fluid {
          src
          srcWebp
        }
      }
      photo2 {
        fluid {
          src
          srcWebp
        }
      }
      photo3 {
        fluid {
          src
          srcWebp
        }
      }
      photo4 {
        fluid {
          src
          srcWebp
        }
      }
    }
    sound: contentfulSound {
      song {
        file {
          url
        }
      }
    }
    site {
      siteMetadata {
        links {
          facebook
          instagram
          farfetch
        }
        menu {
            name
            to
          }
      }
    }
  }
`
