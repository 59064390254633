import React from "react"
import hero from '../images/hero.png'
import logo from '../images/marty-logo.png'
import roundMarty from '../images/round-marty.png'

const Hero = () => (
  
  <div className="sm:h-screen md:pt-6 pb-12 lg:pb-16 flex overflow-x-hidden overflow-y-hidden relative ">
    <div class="glitch absolute  md:w-screen md:h-screen">
      <div class="glitch__item"></div>
      <div class="glitch__item"></div>
      <div class="glitch__item"></div>
      <div class="glitch__item"></div>
      <div class="glitch__item"></div>
    </div>
    <div className="hidden z-10 md:flex md:justify-end items-center px-12 w-full">
      <div className="md:w-2/5 flex flex-col justify-end">
        <img alt="Logo" className="" src={logo} />
        <span className="text-white text-base md:text-xl lg:text-5xl font-univers md:-mt-8 lg:-mt-12 mb-8 tracking-tighter leading-none w-2/3">
          fashion shop since 1974
        </span>
      </div>
    </div>
    <div className="flex md:hidden absolute flex flex-row items-end h-screen">
      <span className="text-white tracking-tighter leading-none text-6xl font-univers mb-16">
        fashion shop since 1974
      </span>
    </div>
    
    {/* <div className="relative w-2/3 z-10">
      <img className="md:h-full md:w-auto md:max-w-none" src={hero}></img>
    </div>
     */}
    
  </div>
)

export default Hero
