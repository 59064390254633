import React from 'react'

const HorizontalScrollingLoop = () => {
    return (
        <div className="my-8">
            <div className="wrapper  font-serif">
                <div className="marquee reverse">
                    <p className="text-4xl md:text-6xl mx-2 text-white tracking-wider">
                    {" "} RICERCA & EMOZIONE. CONTAMINAZIONE & PERSONALITÀ. EQUILIBRIO & IDENTITÀ.{" "}
                    </p>
                    <p className="text-4xl md:text-6xl mr-2 text-white tracking-wider">
                    {" "} RICERCA & EMOZIONE. CONTAMINAZIONE & PERSONALITÀ. EQUILIBRIO & IDENTITÀ.{" "}
                    </p>
                </div>
            </div>
            <div className="wrapper font-univers">
                <div className="marquee mx-2">
                    <p className="text-4xl md:text-6xl  text-white tracking-tighter">
                    {" "} Marty è un percorso visionario che non passa
                    soltanto da capi, abiti e accessori, ma anche
                    dalle persone che vivono questo luogo. {" "}
                    </p>
                    <p className="text-4xl md:text-6xl text-white  tracking-tighter">
                    {" "} Marty è un percorso visionario che non passa
                    soltanto da capi, abiti e accessori, ma anche
                    dalle persone che vivono questo luogo. {" "}
                    
                    </p>
                </div>
            </div>
        </div>

        
    )
}

export default HorizontalScrollingLoop